// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@font-face {
	font-family: 'Circular';
	font-weight: 400;
	src: url(../fonts/Circular-Book.ttf) format('truetype');
}
@font-face {
	font-family: 'Circular';
	font-weight: 500;
	src: url(../fonts/Circular-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'Circular';
	font-weight: 700;
	src: url(../fonts/Circular-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'Circular';
	font-weight: 900;
	src: url(../fonts/Circular-Black.ttf) format('truetype');
}
