@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './fonts';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

body {
	background-color: white;
	height: 100%;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

:not(pre) > code {
	background-color: #eeeeee;
	direction: ltr;
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 2px 4px;
	white-space: pre;
	word-break: normal;
	word-spacing: normal;
}
