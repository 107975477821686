.quill {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 200px;
	width: 100%;
}

.quill .ql-toolbar {
	align-items: center;
	background-color: #f4f6f9;
	border: 0;
	border-bottom: 2px solid #ffffff;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	display: flex;
	flex: 0 0 50px;
	flex-direction: row;
}

.quill .ql-toolbar .ql-formats {
	margin-right: 30px;
}

.quill .ql-container {
	background-color: #f4f6f9;
	border: 0;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	display: flex;
	flex: 1;
}

.quill .ql-container .ql-editor {
	color: #454867;
	flex: 1;
	font-family: 'Circular', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;
	height: auto;
	letter-spacing: -0.2px;
	line-height: 1.3;
}

.quill .ql-container .ql-editor:before {
	font-style: normal;
}

.quill .ql-container .ql-tooltip.ql-editing {
	align-items: center;
	border: 0;
	display: flex;
	height: 45px;
	left: 10px !important;
	top: 0 !important;
}
.quill .ql-container .ql-tooltip.ql-hidden {
	display: none !important;
}
